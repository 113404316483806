(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/customer-login-panel/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/lb-ui/customer-login-panel/assets/javascripts/init.js');
"use strict";


const SELECTOR_LOGIN_BUTTON = '.js-customer-login-login-button';
const SELECTOR_REGISTER_BUTTON = '.js-customer-login-register-button';
const TRACKING_CATEGORY = 'Navigation';
const TRACKING_ACTION = 'login_bar';
function addClickHandlers() {
  const registerButton = document.querySelector(SELECTOR_REGISTER_BUTTON);
  if (registerButton) {
    registerButton.addEventListener('click', clickEvent => {
      var _clickEvent$target;
      clickEvent.preventDefault();
      const trackingEvent = {
        category: TRACKING_CATEGORY,
        action: TRACKING_ACTION,
        opt_label: ((_clickEvent$target = clickEvent.target) === null || _clickEvent$target === void 0 ? void 0 : _clickEvent$target.innerText) || '(register)',
        opt_value: 1
      };
      svs.components.analytics.trackEvent(trackingEvent, () => svs.components.customer_login.login({
        newCustomer: true
      }));
    });
  }
  const loginButton = document.querySelector(SELECTOR_LOGIN_BUTTON);
  if (loginButton) {
    loginButton.addEventListener('click', clickEvent => {
      var _clickEvent$target2;
      clickEvent.preventDefault();
      const trackingEvent = {
        category: TRACKING_CATEGORY,
        action: TRACKING_ACTION,
        opt_label: ((_clickEvent$target2 = clickEvent.target) === null || _clickEvent$target2 === void 0 ? void 0 : _clickEvent$target2.innerText) || '(login)',
        opt_value: 2
      };
      svs.components.analytics.trackEvent(trackingEvent, () => svs.components.customer_login.login());
    });
  }
}
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', addClickHandlers);
} else {
  addClickHandlers();
}

 })(window);